import { DistrictAssignmentContext } from '../../../../../../duck/context';
import {
  Box,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Text,
  Drawer as UIDrawer,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sxLightScrollBar } from '@lon/shared/constants';
import { WorkingLocation } from '@lon/shared/contexts';
import { IdPart } from '@lon/shared/enums';
import { useAuth } from '@lon/shared/hooks';
import {
  useGetStudentsInClassesQuery,
  usePutDistrictStudentAssignmentsMutation,
} from '@lon/shared/requests';
import { apolloClient } from '@lon/shared/utils';
import {
  FormValues,
  FormattedStudents,
  VALIDATION_SCHEMA,
  getGradeLevelsFromDAs,
} from './duck';
import { NoStudents, StudentsList } from './components';

const Drawer: React.FC<{
  isOpen: boolean;
  onClose(): void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  const [{ user }] = useAuth();
  const { currentSchoolId: schoolId = '' } = React.useContext(WorkingLocation);
  const { districtAssignments } = React.useContext(DistrictAssignmentContext);
  const toast = useToast();

  const [putDistrictStudentAssignments, { loading: puttingStudents }] =
    usePutDistrictStudentAssignmentsMutation();

  const { data: studentsInClasses, loading: studentsLoading } =
    useGetStudentsInClassesQuery({
      fetchPolicy: 'no-cache',
      variables: {
        schoolId,
        teachersId: user.userId,
      },
    });

  const gradeLevels = React.useMemo(
    () => getGradeLevelsFromDAs(districtAssignments),
    [districtAssignments]
  );

  const notAddedStudentsInClasses = React.useMemo(() => {
    if (!studentsInClasses || !gradeLevels.length) {
      return {};
    }

    const addedStudentsIds = districtAssignments.reduce((acc: string[], da) => {
      if (da?.studentAssignments) {
        acc.push(...(da.studentAssignments.map((sa) => sa.studentId) || []));
      }

      return acc;
    }, []);

    return studentsInClasses.classes?.collection?.reduce(
      (acc: Record<string, FormattedStudents>, cls) => {
        if (!cls || !gradeLevels.includes(cls.gradeOrCourse as string)) {
          return acc;
        }

        acc[cls.gradeOrCourse as string] = [
          ...(acc[cls.gradeOrCourse as string] || []),
          ...(cls.classStudents?.collection?.reduce((clsAcc: any[], st) => {
            const studentId = (st?.student?.id || '').split(IdPart.Users)[1];

            const isAlreadyAdded = acc[cls.gradeOrCourse as string]?.find(
              (accSt) => accSt?.id === st?.student?.id
            );

            if (!addedStudentsIds.includes(studentId) && !isAlreadyAdded) {
              clsAcc.push(st?.student);
            }

            return clsAcc;
          }, []) || []),
        ];

        return acc;
      },
      {}
    ) as Record<string, FormattedStudents>;
  }, [studentsInClasses, gradeLevels]);

  const formProviderProps = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      studentIds: {},
    },
  });

  const handleSubmit = async (val: FormValues) => {
    try {
      const promises = Object.keys(val.studentIds).reduce(
        (acc: Promise<any>[], gradeLevel) => {
          const selectedStudentsIds = Object.keys(
            val.studentIds[gradeLevel]
          ).filter((stKey) => val.studentIds[gradeLevel][stKey]);

          const currentGradeDAs = districtAssignments.filter(
            (da) => da?.gradeLevel === gradeLevel
          );

          const classes =
            studentsInClasses?.classes?.collection?.filter(
              (cl) => cl?.gradeOrCourse === gradeLevel
            ) || [];

          const assignmentIdStudentMap = selectedStudentsIds.reduce<
            Record<string, { studentIds: string[] }>
          >((acc, val) => {
            const currentClass = classes.find((cl) =>
              cl?.classStudents?.collection?.find(
                (st) => st?.student?.id === val
              )
            );

            const currentGradeDA = currentGradeDAs.find(
              (da) =>
                da?.classId === currentClass?.id?.replace(IdPart.Classes, '')
            );

            if (!currentGradeDA) {
              return acc;
            }

            const assignmentId = currentGradeDA.assignmentId as string;

            if (acc[assignmentId]) {
              acc[assignmentId] = {
                studentIds: [
                  ...acc[assignmentId].studentIds,
                  val.replace(IdPart.ManageUsers, ''),
                ],
              };
            } else {
              acc[assignmentId] = {
                studentIds: [val.replace(IdPart.ManageUsers, '')],
              };
            }

            return acc;
          }, {});

          const assignmentIds = Object.keys(assignmentIdStudentMap);

          if (assignmentIds.length) {
            assignmentIds.forEach((assignmentId) =>
              acc.push(
                putDistrictStudentAssignments({
                  variables: {
                    input: {
                      assignmentId,
                      newStudentIds:
                        assignmentIdStudentMap[assignmentId].studentIds,
                    },
                  },
                })
              )
            );
          }

          return acc;
        },
        []
      );

      await Promise.all(promises);

      apolloClient.refetchQueries({
        include: [
          'getDistrictAssignments',
          'getClassesForDaDetails',
          'getStudentsInClasses',
        ],
      });

      formProviderProps.reset({
        studentIds: {},
      });

      toast({
        title: t('addStudents.successToastTitle'),
        description: t('addStudents.successToastWithoutNames'),
        variant: 'success-light',
        isClosable: true,
      });

      onClose();
    } catch {
      toast({
        title: t('addStudents.errorToastTitle'),
        description: t('addStudents.errorToast'),
        variant: 'error-light',
        isClosable: true,
      });
    }
  };

  const studentIds = formProviderProps.watch('studentIds');
  const atLeastOneIsSelected = React.useMemo(() => {
    if (!studentIds) {
      return false;
    }

    return Object.values(studentIds).some((stCollection) =>
      Object.values(stCollection).some((st) => st)
    );
  }, [studentIds]);

  const hasStudentsToAdd = Object.values(notAddedStudentsInClasses).some(
    (c) => !!c.length
  );

  return (
    <UIDrawer
      variant="formDrawer"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />

      <FormProvider {...formProviderProps}>
        <Box
          as="form"
          id="addStudents"
          className="emotion-css-cache userInputForm"
          noValidate
          onSubmit={formProviderProps.handleSubmit(handleSubmit)}
        >
          <DrawerContent boxShadow="none">
            <DrawerCloseButton />
            <DrawerHeader>{t('addStudents.title')}</DrawerHeader>
            <DrawerBody css={sxLightScrollBar} color="primary.800">
              {!studentsLoading && hasStudentsToAdd && (
                <>
                  <Box px={8} py={6}>
                    <Text variant="s3" mt={2} whiteSpace="pre-wrap">
                      {t('addStudents.gradeDescription')}
                    </Text>
                  </Box>

                  {gradeLevels.map((gradeLevel) =>
                    notAddedStudentsInClasses[gradeLevel].length ? (
                      <StudentsList
                        key={gradeLevel}
                        gradeLevel={gradeLevel}
                        notAddedStudents={notAddedStudentsInClasses[gradeLevel]}
                      />
                    ) : null
                  )}
                </>
              )}

              {!studentsLoading && !hasStudentsToAdd && <NoStudents />}
            </DrawerBody>

            <DrawerFooter>
              <HStack justify="space-between" width="100%">
                <Button variant="outline" mr={3} onClick={onClose}>
                  <Text as="span" isTruncated>
                    {t('addStudents.backButton')}
                  </Text>
                </Button>
                <Button
                  form="addStudents"
                  type="submit"
                  isLoading={studentsLoading || puttingStudents}
                  variant="solid"
                  isDisabled={!atLeastOneIsSelected}
                >
                  <Text as="span" isTruncated>
                    {t('addStudents.addButton')}
                  </Text>
                </Button>
              </HStack>
            </DrawerFooter>
          </DrawerContent>
        </Box>
      </FormProvider>
    </UIDrawer>
  );
};

export default Drawer;
