import { tableContentUtils } from '../../duck';
import { getBorderBottomWidth, getRightBorderWidth } from '../../duck/utils';
import { Flex, Tbody, Td, Text, Tr } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';
import { useGetTheme, useThemeStyleValue } from '@lon/shared/hooks';
import { getThemeStyleValue } from '@lon/shared/utils';
import type { tBodyTypes } from './duck';

const TBody = <Data extends object>({
  table,
  enableStickyColumns,
  enableVerticalBorders,
  enableRowSelection,
  fontSize = 'sm',
  py = 2,
  px = 4,
  activeRowId,
  withoutBottomBorder,
  ...rest
}: tBodyTypes.TBodyProps<Data>) => {
  const currentTheme = useGetTheme();
  const borderColor = useThemeStyleValue(
    'var(--chakra-colors-secondary-200)',
    'white'
  );

  return (
    <Tbody tabIndex={0} {...rest}>
      {table.getRowModel().rows.map((row, rowIndex) => {
        const canExpanded =
          row.getCanExpand() || (row?.original as any)?.subRows;
        const cells = row.getVisibleCells();
        const isHighlight = !!(row.original as any)?.highlight;
        const isActiveRow = activeRowId === row.id;
        const isLastRow = rowIndex === table.getRowModel().rows.length - 1;

        return (
          <Tr
            key={row.id}
            onKeyDown={(event) =>
              tableContentUtils.handleRowsNavigation({
                event,
                cell: cells[0],
                index: row.index,
              })
            }
            tabIndex={0}
            id={`${row.index}_row`}
            _focusVisible={{
              boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
              outline: '1px solid var(--chakra-colors-primary-800)',
              position: 'sticky',
              borderLeft: '8px solid transparent',
              borderRight: '8px solid transparent',
              zIndex: 100,
            }}
          >
            {(row?.original as any)?.isColumnsMerged ? (
              <Td colSpan={4} backgroundColor="secondary.50">
                <Text variant="n1">{(row?.original as any)?.title}</Text>
              </Td>
            ) : (
              cells.map((cell, index) => {
                // TODO: to type this correctly
                const meta: any = cell.column.columnDef.meta;
                const isFirstCell = index === 0;
                const isLastCell = index === cells.length - 1;
                const isActiveFirstCell = isFirstCell && isActiveRow;
                const rightBorderWidth = getRightBorderWidth<object>({
                  isLast: index + 1 === cells.length,
                  isFirst:
                    cells.length < 2 || enableRowSelection
                      ? index === 0
                      : false,
                  enableVerticalBorders,
                  enableRowSelection,
                });
                const borderBottomWidth = getBorderBottomWidth({
                  withoutBottomBorder,
                  isLastRow,
                  currentTheme,
                });

                return (
                  <Td
                    minWidth={meta?.minWidth}
                    position={
                      isActiveFirstCell && !enableStickyColumns
                        ? 'relative'
                        : 'unset'
                    }
                    textAlign={
                      (cell.column.columnDef.meta as any)?.cellAlign || 'left'
                    }
                    onKeyDown={(event) =>
                      tableContentUtils.handleArrowsTableNavigation({
                        event,
                        rowIndex,
                      })
                    }
                    px={px}
                    py={
                      typeof (cell.column.columnDef.meta as any)?.padding ===
                      'number'
                        ? (cell.column.columnDef.meta as any)?.padding
                        : py
                    }
                    paddingInlineStart={4}
                    paddingInlineEnd={index + 1 === cells.length ? 8 : 4}
                    key={cell.id}
                    id={`${rowIndex}_${
                      cell.column.columnDef.id ||
                      // @ts-ignore
                      cell.column.columnDef.accessorKey
                    }`}
                    isNumeric={meta?.isNumeric}
                    border="none"
                    boxShadow={`inset -${rightBorderWidth}px -${borderBottomWidth}px ${borderColor}`}
                    backgroundColor={getThemeStyleValue(
                      isHighlight || isActiveRow
                        ? 'secondary.50'
                        : canExpanded
                        ? 'gray.25'
                        : 'white',
                      'secondary.1000'
                    )(currentTheme)}
                    _focusVisible={{
                      boxShadow: '0 0 0 4px var(--chakra-colors-blue-600)',
                      outline: '1px solid var(--chakra-colors-primary-800)',
                      position: 'sticky',
                    }}
                    fontWeight={canExpanded ? '700' : '400'}
                    fontSize={fontSize}
                    {...(index === 0 && {
                      padding: 0,
                      paddingLeft: enableRowSelection ? 4 : 8,
                      paddingRight: enableRowSelection ? 0.5 : 4,
                    })}
                    {...(isActiveFirstCell && {
                      _before: {
                        content: `''`,
                        position: 'absolute',
                        top: '9%',
                        left: 0,
                        zIndex: 1,
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                        background: 'primary.400',
                        width: '4px',
                        height: '82%',
                      },
                    })}
                    {...(tableContentUtils.shouldShowVerticalBorders(
                      index,
                      enableVerticalBorders,
                      enableRowSelection,
                      enableStickyColumns
                    ) && {
                      _after: {
                        content: `''`,
                        position: 'absolute',
                        right: '-1px',
                        top: 0,
                        width: '1px',
                        height: '100%',
                        backgroundColor: '#E1E7F0',
                        zIndex: 1,
                      },
                    })}
                    {...tableContentUtils.getStickyStyles(
                      index,
                      row.getVisibleCells().length,
                      enableStickyColumns,
                      enableRowSelection
                    )}
                    {...(isLastRow && isFirstCell
                      ? { borderBottomLeftRadius: 'md' }
                      : {})}
                    {...(isLastRow && isLastCell
                      ? { borderBottomRightRadius: 'md' }
                      : {})}
                  >
                    {cell.column.id === 'actions' ? (
                      <Flex justify="center">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Flex>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </Td>
                );
              })
            )}
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default TBody;
